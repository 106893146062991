import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserRole } from '../../../../constant-enum-type/user';
import { useMeContext } from '../../../auth/contexts/me';
import Loading from '../../components/common/Loading';

interface Props {
  component: JSX.Element;
}

const BranchRoute: React.FC<Props> = (props) => {
  const { component } = props;
  const { me, isInitialLoaded } = useMeContext();
  if (!isInitialLoaded) return <Loading />;
  if (!me) return <Redirect to="/login" />;
  if (me && me.role === UserRole.Admin) return <Redirect to="/admin" />;
  if (me && me.role === UserRole.Support) return <Redirect to="/admin/branches" />;
  return component;
};

export default BranchRoute;
