import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';

import reportWebVitals from './reportWebVitals';
import App from './features/app/components/App';
import Providers from './features/app/components/common/Provider';

import './index.css';

moment.tz.setDefault('Asia/Bangkok');
moment.locale('en');

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
