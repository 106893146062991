import React, { Suspense, lazy } from 'react';
import { UserRole } from '../../../constant-enum-type/user';

import Loading from '../components/common/Loading';

const renderPageComponent = (Comp: React.FC) => (
  <Suspense fallback={<Loading />}>
    <Comp />
  </Suspense>
);

export type IRoute = {
  path: string;
  role?: UserRole[];
  component: JSX.Element;
};

// admin path
const adminRoutes: IRoute[] = [
  {
    path: '/admin',
    role: [UserRole.Admin],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.dashboard.page" */'../../../pages/admin/dashboard')),
    ),
  },
  {
    path: '/admin/transactions',
    role: [UserRole.Admin],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.transactions.page" */'../../../pages/admin/transactions')),
    ),
  },
  {
    path: '/admin/codes',
    role: [UserRole.Admin],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.codes.page" */'../../../pages/admin/codes')),
    ),
  },
  {
    path: '/admin/promo',
    role: [UserRole.Admin],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.promo.page" */'../../../pages/admin/promo')),
    ),
  },
  {
    path: '/admin/branches',
    role: [UserRole.Admin, UserRole.Support],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.branch.page" */'../../../pages/admin/branch')),
    ),
  },
  {
    path: '/admin/branches/:id',
    role: [UserRole.Admin, UserRole.Support],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.branch-detail.page" */'../../../pages/admin/branch/branch-detail')),
    ),
  },
];

// branch path
const branchRoutes: IRoute[] = [
  {
    path: '/branch',
    role: [UserRole.Branch],
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.branch.page" */'../../../pages/branch')),
    ),
  },
];

export const routes: IRoute[] = [
  {
    path: '/login',
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.login.page" */'../../../pages/auth/login')),
    ),
  },
  ...adminRoutes,
  ...branchRoutes,
  {
    path: '*',
    component: renderPageComponent(
      lazy(() => import(/* webpackChunkName: "common.not-found.page" */'../../../pages/not-found')),
    ),
  },
];
