import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="grid items-center justify-center h-full min-h-screen bg-brown-100">
      <img className="w-20 h-20" src="/icons/loading.svg" alt="loading" />
    </div>
  );
};

export default Loading;
