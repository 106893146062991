import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserRole } from '../../../constant-enum-type/user';
import { useMeContext } from '../../auth/contexts/me';
import Router from '../routes';
import Loading from './common/Loading';

const App: React.FC = () => {
  const meHandler = useMeContext();
  const history = useHistory();

  useEffect(() => {
    meHandler.load();
  }, []);

  useEffect(() => {
    if (meHandler.me) {
      const user = meHandler.me;
      if (user.role === UserRole.Admin) history.replace('/admin');
      if (user.role === UserRole.Support) history.replace('/admin/branches');
    } else {
      history.replace('/login');
    }
  }, [meHandler.me]);

  if (!meHandler.isInitialLoaded) {
    return <Loading />;
  }

  return (
    <>
      <Router />
    </>
  );
};

export default App;
