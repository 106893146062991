import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { IRoute, routes } from './route-paths';
import AdminRoute from './components/AdminRoute';
import BranchRoute from './components/BranchRoute';
import PublicRoute from './components/PublicRoute';
import { UserRole } from '../../../constant-enum-type/user';

const defineRoute = (route: IRoute) => {
  if ((route.role || []).includes(UserRole.Admin)) {
    return <AdminRoute component={route.component} />;
  }
  if ((route.role || []).includes(UserRole.Branch)) {
    return <BranchRoute component={route.component} />;
  }
  return <PublicRoute component={route.component} />;
};

const Router: React.FC = () => {
  return (
    <Switch>
      {routes.map(route => {
        const path = route.path;
        return (
          <Route key={path} path={path} exact>
            {defineRoute(route)}
          </Route>
        );
      })}
    </Switch>
  );
};

export default Router;
