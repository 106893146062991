export enum UserRole {
  Admin = 'Admin',
  Branch = 'Branch',
  Support = 'Support',
}

export type User = {
  _id: string;
  role: UserRole;
  email: string;
  name: {
    first_name: string;
    last_name: string;
  };
};
