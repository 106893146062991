import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { MeProvider } from '../../../auth/contexts/me';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

const Providers:React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MeProvider>
          {children}
        </MeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Providers;
