import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserRole } from '../../../../constant-enum-type/user';

import { useMeContext } from '../../../auth/contexts/me';
import Layout from '../../components/common/Layout';
import Loading from '../../components/common/Loading';
// import { Redirect } from 'react-router-dom';
// import { UserRole } from '../../../../models/user';
// import { useUserAuth } from '../../../auth/hooks/UserAuthContext';
// import Layout from '../Layout';
// import Loading from '../Loading';

interface Props {
  component: JSX.Element;
}

const AdminRoute: React.FC<Props> = (props) => {
  const { component } = props;
  const { me, isInitialLoaded } = useMeContext();
  if (!isInitialLoaded) return <Loading />;
  if (!me) return <Redirect to="/login" />;
  return <Layout isLogin={!!me._id}>{component}</Layout>;
};

export default AdminRoute;
