import config from '../../../config';
import { APIResponse } from '../../../constant-enum-type/api';

import { authTokenHandler } from './local-storage';

const baseURL = config.apiEndpoint;

const errorResponse: APIResponse = { success: false as const, error_code: 500, error_message: 'Something went wrong!' };

const processFetch = <T = any>(res: any) => {
  return Promise.all([res.ok, res.json()])
    .then(([ok, response]) => {
      if (!ok) {
        throw new Error(errorResponse.error_message);
      }
      // if (!response.success) {
      //   throw new Error(response.error_message);
      // }
      return response as APIResponse<T>;
    });
};

type TOption = {
  headers?: RequestInit['headers'];
  body?: Record<string, any>;
  file?: FormData;
};

export default {
  get: <T>(url: string, option: TOption = {}): Promise<APIResponse<T>> => {
    const { headers = {} } = option;
    const authToken = authTokenHandler.get();
    return fetch(`${baseURL}${url}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...headers,
      },
    }).then((res) => processFetch<T>(res));
  },
  post: <T>(url: string, option: TOption = {}): Promise<APIResponse<T>> => {
    const { headers = {}, file, body = {} } = option;
    const authToken = authTokenHandler.get();
    return fetch(`${baseURL}${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        ...(!file ? { 'Content-Type': 'application/json' } : {}),
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...headers,
      },
      credentials: 'include',
      body: file || JSON.stringify(body),
    }).then((res) => processFetch<T>(res));
  },
  delete: <T>(url: string, option: TOption = {}): Promise<APIResponse<T>> => {
    const { body = {}, headers = {} } = option;
    const authToken = authTokenHandler.get();
    return fetch(`${baseURL}${url}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...headers,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((res) => processFetch<T>(res));
  },
  put: <T>(url: string, option: TOption = {}): Promise<APIResponse<T>> => {
    const { body = {}, headers = {} } = option;
    const authToken = authTokenHandler.get();
    return fetch(`${baseURL}${url}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...headers,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((res) => processFetch<T>(res));
  },
};
