import { authTokenHandler } from '../../app/utils/local-storage';
import { useMeContext } from '../contexts/me';

const useLogout = (): {
  logout: () => void;
} => {
  const meHandler = useMeContext();
  const logout = () => {
    authTokenHandler.clear();
    meHandler.clear();
  };
  return {
    logout,
  };
};

export default useLogout;
