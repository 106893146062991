import { APIResponse } from '../../constant-enum-type/api';
import { User } from '../../constant-enum-type/user';
import api from '../app/utils/api';

export function login(email: string, password: string) {
  return api.post<APIResponse<{ access_token: string }>>('/auth/login', { body: { email, password } });
}

export function getMe() {
  return api.get<APIResponse<{ user: User | null }>>('/users/me');
}

export default {};
