import React, { useState } from 'react';
import { Button, Layout, Menu, Popconfirm } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  DollarCircleOutlined,
  FileOutlined,
  ShopOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import useLogout from '../../../auth/hooks/use-logout';
import { useMeContext } from '../../../auth/contexts/me';
import { UserRole } from '../../../../constant-enum-type/user';

const { Header, Sider, Content } = Layout;

interface LayoutCompProps {
  isLogin: boolean;
}

enum MenuPath {
  DASHBOARD = '/admin',
  CODES = '/admin/codes',
  TRANSACTIONS = '/admin/transactions',
  PROMO = '/admin/promo',
  Branches = '/admin/branches',
}

const menus = [
  {
    label: 'Overview',
    icon: <HomeOutlined />,
    link: MenuPath.DASHBOARD,
    role: [UserRole.Admin],
    isShowMenu: true,
  },
  {
    label: 'Branches',
    icon: <ShopOutlined />,
    link: MenuPath.Branches,
    role: [UserRole.Admin, UserRole.Support],
    isShowMenu: true,
  },
  {
    label: 'User Code',
    icon: <UserOutlined />,
    link: MenuPath.CODES,
    role: [UserRole.Admin],
    isShowMenu: true,
  },
  {
    label: 'Transactions',
    icon: <DollarCircleOutlined />,
    link: MenuPath.TRANSACTIONS,
    role: [UserRole.Admin],
    isShowMenu: true,
  },
  {
    label: 'Promo Code',
    icon: <FileOutlined />,
    link: MenuPath.PROMO,
    role: [UserRole.Admin, UserRole.Support],
    isShowMenu: true,
  },
];

const LayoutComp: React.FC<LayoutCompProps> = ({ isLogin, children }) => {
  const { me } = useMeContext();
  const location = useLocation();
  const { logout } = useLogout();

  const [isHideSider, setIsHideSider] = useState(false);
  const [visible, setVisible] = useState(false);

  const title = menus.find(m => m.link === location.pathname);

  const filterMenu = me ? menus.filter(menu => menu.role.includes(me.role)) : menus;

  const toggle = () => {
    setIsHideSider(prevHide => !prevHide);
  };

  if (!isLogin) return <>{children}</>;

  return (
    <Layout className="h-full min-h-screen">
      <Sider trigger={null} collapsible collapsed={isHideSider}>
        <div className="w-11/12 px-2 py-4 mx-auto">
          <img className="w-full mx-auto" src="/icons/video-logo-white.png" alt="Logo" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
          {filterMenu.map(menu => (
            <Menu.Item key={menu.link} icon={menu.icon}>
              <Link to={menu.link}>{menu.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="flex items-center px-8 bg-white">
          {React.createElement(isHideSider ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div className="flex items-center justify-between w-full py-8 ml-5">
            <h1 className="m-0 text-2xl text-blue-700">{title && title.label}</h1>
            <Popconfirm
              title="Are You Sure?"
              visible={visible}
              onConfirm={() => logout()}
              onCancel={() => setVisible(false)}
            >
              <Button danger onClick={() => setVisible(true)} className="flex items-center" type="default" icon={<LogoutOutlined />}>
                Logout
              </Button>
            </Popconfirm>
          </div>
        </Header>
        <Content className="p-8 overflow-y-scroll">
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComp;
