export enum ErrorMessage {
  AUTH_UNAUTHORIZED = 'Sculpture: Unauthorized',
  CODE_ALREADY_REDEEMED = 'Sculpture: Code already redeemed',
  CODE_NOT_FOUND = 'Sculpture: Code not found',

  DEAL_NOT_FOUND = 'Sculpture: Deal not found',
  DEAL_MAXIMUM_BOOKINGS = 'Sculpture: The maximum number of bookings has been reached.',

  PAYMENT_FAIL_TO_CHARGE = 'Sculpture: Failed to charge',

  BOOKING_NOT_FOUND = 'Sculpture: Booking not found',
  BOOKING_HAS_NOT_PAID = 'Sculpture: Booking has not paid',

  TOKEN_HAS_EXPIRED = 'Sculpture: Token has expired',

  USER_NOT_FOUND = 'Sculpture: User not found',
}

export type APIResponse<Custom = { [key: string]: string }> = ({
  success: true;
} & Custom) | {
  success: false;
  error_message: string;
  error_code: number;
};
