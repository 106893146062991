/* eslint-disable import/prefer-default-export */
import React, { createContext, useContext } from 'react';
import { User } from '../../../constant-enum-type/user';
import useMe, { Me } from '../hooks/use-me';

const MeContext = createContext<Me>({
  isInitialLoaded: false,
  me: null,
  load: () => Promise.resolve(false),
  refresh: () => Promise.resolve(false),
  clear: () => Promise.resolve(false),
});

export function useMeContext(): Me<User> {
  return useContext(MeContext);
}

export const MeProvider: React.FC = ({ children }) => {
  const me = useMe();
  return <MeContext.Provider value={me}>{children}</MeContext.Provider>;
};
