import { useCallback, useState } from 'react';

import { ErrorMessage } from '../../../constant-enum-type/api';
import { getMe } from '../api-actions';
import { authTokenHandler } from '../../app/utils/local-storage';
import { User } from '../../../constant-enum-type/user';

export type Me<T = User> = {
  isInitialLoaded: boolean;
  me: T | null;
  load: () => Promise<boolean>;
  refresh: () => Promise<boolean>;
  clear: () => void;
};
type InitialState = Pick<Me, 'me' | 'isInitialLoaded'>;

function useMe(): Me {
  const [state, setState] = useState<InitialState>({
    isInitialLoaded: false,
    me: null,
  });
  const load = useCallback(async () => {
    if (state.isInitialLoaded) return false;
    const authToken = authTokenHandler.get();
    if (!authToken) {
      setState({ isInitialLoaded: true, me: null });
      return true;
    }
    try {
      const result = await getMe();
      if (!result.success) {
        setState({ isInitialLoaded: true, me: null });
        return true;
      }
      setState({ isInitialLoaded: true, me: result.user });
    } catch (e) {
      setState({ isInitialLoaded: true, me: null });
      return true;
    }
    return true;
  }, [state.isInitialLoaded]);
  const refresh = useCallback(async () => {
    if (!state.isInitialLoaded) return false;
    const authToken = authTokenHandler.get();
    if (!authToken) {
      setState((currentState) => ({ ...currentState, me: null }));
      return true;
    }
    const result = await getMe();
    if (!result.success) {
      if (result.error_message === ErrorMessage.USER_NOT_FOUND) {
        setState((currentState) => ({ ...currentState, me: null }));
        return true;
      }
      return false;
    }
    setState((currentState) => ({ ...currentState, me: result.user }));
    return true;
  }, [state.isInitialLoaded]);
  const clear = useCallback(() => {
    setState((currentState) => ({ ...currentState, me: null }));
  }, []);
  return {
    ...state,
    load,
    refresh,
    clear,
  };
}

export default useMe;
