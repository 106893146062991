const defaultConfig = {
  apiEndpoint: 'https://video-api-uat.sculpturebangkok.com',
  videoEndpoint: 'https://video-app-uat.sculpturebangkok.com',
  userCredential: 'sculpture-video-backoffice-uat',
};

const productionConfig = {
  apiEndpoint: 'https://video-api-prod.sculpturebangkok.com',
  videoEndpoint: 'https://video-app-prod.sculpturebangkok.com',
  userCredential: 'sculpture-video-backoffice-prod',
};

const config = {
  ...defaultConfig,
  ...(process.env.REACT_APP_ENV === 'production' ? productionConfig : {}),
};

export default config;
